import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

type ComponentNameProps = PropsWithChildren;

export const PageDialog: FC<ComponentNameProps> = ({ children }) => (
  <Container>{children}</Container>
);

const Container = styled.div`
  padding: 8px 0;
  min-height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right top,
    #21416a,
    #1b3657,
    #162b46,
    #122134,
    #0d1724
  );
`;
