import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useMnemonicPhraseStore } from '@/entities/mnemonic/store/mnemonic-phrase.store';
import { useMyHubProfile } from '@/features/my-hub/myHubProfileStore';
import { EnterSeedDialog } from '@/shared/components/seed/EnterSeedDialog';
import { PageDialog } from '@/shared/layouts/PageDialog';
import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';
import { EncryptionType } from '@/shared/lib/secure-json/core/crypto-core/types';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';

const ORIGIN = process.env.NEXT_PUBLIC_SELF_URL_BASE as string;

const MainPageForExportedMode = () => {
  const { push } = useRouter();
  const { setKeyPair } = useKeyPairStore();
  const { inputPhrase: mnemonicPhrase } = useMnemonicPhraseStore();
  const { setData } = useMyHubProfile();
  const [error, setError] = useState('');
  const [pubKey, setPubKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPubKey = async () => {
      const url = `${ORIGIN}/database/database.json`;

      const response = await fetch(url);
      const data = await response.json();
      const mainUserData = data.mainUserData;
      const profile = data.profile;

      if (mainUserData) {
        setPubKey(mainUserData.publicKey ?? '');
      }

      if (profile) {
        setData({
          firstName: profile.firstName ?? '',
          lastName: profile.lastName ?? '',
        });
      }
    };

    fetchPubKey();
  }, []);

  const onEnter = async (): Promise<void> => {
    setError('');
    setIsLoading(true);

    const mnemonic = mnemonicPhrase.trim();
    console.log(mnemonic);

    if (!mnemonic) {
      setError('Empty seed phrase');
      setIsLoading(false);
      return;
    }

    if (mnemonic.split(/\s+/).length < 12) {
      setError('Please, enter full mnemonic phrase');
      setIsLoading(false);
      return;
    }

    const encryptionType: EncryptionType = 'ecies'; // TODO crypto myhub data must be in ecies
    const cryptoCore = new CryptoCore(encryptionType);

    const { publicKey, privateKey } =
      await cryptoCore.mnemonicToKeyPair(mnemonic);

    if (publicKey !== pubKey) {
      setError('Incorrect seed phrase');
      setIsLoading(false);
      return;
    }

    setKeyPair({ privateKey, publicKey }, encryptionType);
    setIsLoading(false);
    await push({ pathname: '/dashboard' }, undefined, { shallow: true });
  };

  return (
    <PageDialog>
      <EnterSeedDialog isLoading={isLoading} error={error} onEnter={onEnter} />;
    </PageDialog>
  );
};

export default MainPageForExportedMode;
