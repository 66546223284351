import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { Button, Typography } from 'antd';
import styled from 'styled-components';

import { useMnemonicPhraseStore } from '@/entities/mnemonic';
import { COLORS } from '@/shared/constants/colors';
import ImageLogo from '@/shared/icons/logo/logo.svg';
import { Title } from '@/shared/text';
import { EnterMnemonicPhrase } from '@/widgets/mnemonic-phrase/enter-mnemonic-phrase';

type EnterSeedProps = {
  isLoading?: boolean;
  error?: string;
  onEnter: () => void;
};

const { Text, Link } = Typography;

export const EnterSeedDialog: FC<EnterSeedProps> = ({
  isLoading,
  error,
  onEnter,
}) => {
  const { t: ta } = useTranslation('auth', { keyPrefix: 'login' });
  const { t: tc } = useTranslation('common');
  const { deleteSegments, pasteFromKeyboard } = useMnemonicPhraseStore();

  return (
    <Container>
      <Image src={ImageLogo} alt="Logo" width={76} height={48} />
      <Title style={{ marginTop: 24, fontSize: '24px' }}>
        {ta('enter_seed_phrase')}
      </Title>
      <Text
        style={{ margin: '8px 0 24px', fontSize: '16px', textAlign: 'center' }}
      >
        {ta('logged_from_new_device')}
      </Text>
      <EnterMnemonicPhrase hideControls={true} />
      {error && (
        <span style={{ marginTop: 24, fontSize: 16, color: COLORS.error }}>
          {error}
        </span>
      )}
      <Button
        style={{ marginTop: 24 }}
        block
        type="primary"
        size="large"
        onClick={onEnter}
        loading={isLoading}
        data-testid={'continueBtn'}
      >
        {tc('submit')}
      </Button>
      <Link
        style={{ marginTop: 12, cursor: 'pointer' }}
        onClick={pasteFromKeyboard}
        underline
      >
        {ta('pasteFromClipboard')}
      </Link>
      <Link
        style={{ marginTop: 12, cursor: 'pointer' }}
        onClick={deleteSegments}
        underline
      >
        {ta('clearSeedPhrase')}
      </Link>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 24px;
  width: min(548px, calc(100% - 16px));
  min-height: 668px;

  background: ${COLORS.white};
  border-radius: 16px;

  position: relative;
  overflow: hidden;
`;
